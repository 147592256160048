<template>
	<div class="body">
		<div class="top">
			<div class="left">
				<img src="../../static/images/logo1@2x.png" class="logo"/>
				<div class="bar"></div>
				<img src="../../static/images/name@2x.png" class="name"/>
			</div>
			<div class="right">
				<div class="btn" @click="login()">登录</div>
				<div class="btn active">注册</div>
			</div>
		</div>
		<div class="center">
			<div class="right">
				<div class="title">用户注册</div>
				<div class="field">
					<el-input prefix-icon="el-icon-user" type="text" placeholder="请输入用户名" v-model="queryInfo.username"/>
				</div>
				<div class="field">
					<el-input prefix-icon="el-icon-lock" type="password" placeholder="请输入密码" v-model="queryInfo.password" @blur="alertPwdMsg"/>
				</div>
				<div class="field">
					<el-input prefix-icon="el-icon-lock" type="password" placeholder="请再次输入密码" v-model="queryInfo.password2" @blur="alertPwdMsg"/>
				</div>
				<div class="pic">
					<div class="in">
						<el-input prefix-icon="el-icon-picture-outline" type="text" placeholder="请输入验证码" v-model="queryInfo.code"/>
					</div>
					<img :src="captchaImage" class="img" @click="refresh"/>
				</div>
				<div class="field">
					<el-checkbox v-model="queryInfo.checked">我阅读并同意<span @click.stop="read">《用户协议》</span></el-checkbox>
				</div>
				<div class="field">
					<div :class="{'btn':true, 'dim': !queryInfo.checked}" @click="handleRegister">注册</div>
				</div>
				<div class="to">
					<a href="javascript:;" class="link" @click="login()">已有账号？去登录>></a>
				</div>
			</div>
		</div>
		<MyFooter></MyFooter>
	</div>
</template>

<script>
import MyFooter from '@/components/footer.vue';
	export default {
		name: 'Home',
		components: {
			MyFooter
		},
		data() {
			return {
				queryInfo: {
					username: null,
					password: null,
					password2: null,
					code: null
				},
				captchaImage: null
			}
		},
		created() {
			this.refresh()
		},
		mounted() {

		},
		methods: {
			alertPwdMsg(e) {
				
				let pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
				if(!pattern.test(e.target.value)) {
					this.$message.warning('请输入包含数字，字母的6-20位字符')
				}
			},
			handleRegister() {
				if(!this.queryInfo.checked) {
					return ;
				}
				if(!this.queryInfo.username) {
					this.$message.warning('请输入用户名')
					return ;
				}
				let pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
				if(!pattern.test(this.queryInfo.password)) {
					this.$message.warning('请输入包含数字，大小写字母的6-20位字符')
					return ;
				}
				if(this.queryInfo.password2 != this.queryInfo.password) {
					this.$message.warning('两次输入密码不一致')
					return ;
				}
				if(!this.queryInfo.code) {
					this.$message.warning('请输入验证码')
					return ;
				}
				this.$api.common.register(this.queryInfo).then((res) => {
					this.login()
				})
			},
			read() {

			},
			login() {
				this.$router.push('/logon')
			},
			refresh() {
				this.$api.common.captchaImage().then((res) => {
					this.captchaImage = "data:image/gif;base64," + res.data.img
				})
			}
		}
	}
</script>

<style scoped lang="less">
.body {
	min-width: 1280px;
  background-color: #0031BD;
  // background-color: #333;
  min-height: 100vh;
  background-image: url('../../static/images/bg.png');
  background-size: 100% 100%;
  background-position: 0 0;
	.top {
		min-width: 1280px;
		max-width: 1840px;
		margin: 0 auto;
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		.left {
			display: flex;
			align-items: center;
			.logo {
				width: 164px;
				height: auto;
			}
			.bar {
				height: 50px;
				width: 4px;
				background: #fff;
				margin: 15px;
			}
			.name {
				width: 228px;
				height: auto;
			}
		}
		.right {
			padding-right: 40px;
			display: flex;
			align-items: center;
			.btn {
				background: #fff;
				border-radius: 8px;
				margin-right: 20px;
				height: 34px;
				line-height: 34px;
				width: 100px;
				text-align: center;
				font-size: 18px;
				color: #777;
				cursor: pointer;
				// box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.1);
			}
			.btn:hover {
				color: #000;
			}
			.btn.active {
				color: #0031BD;
			}
		}
	}
	.center {
		max-width: 1680px;
    min-width: 1280px;
    width: 80%;
		margin: 0 auto;
		background-image: url('../../static/images/z1.png');
		min-height: calc(100vh - 367px);
		background-size: 800px auto;
  	background-position: left center;
		background-repeat: no-repeat;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		.right {
			background: #fff;
			font-size: 20px;
			width: 400px;
			margin-right: 10%;
			color: #666;
			.title {
				line-height: 54px;
				border-bottom: 1px solid #eee;
				text-align: center;
			}
			.field,.pic,.to {
				margin: 20px;
				.btn {
					line-height: 44px;
					text-align: center;
					color: #fff;
					background: #FC8D16;
					border-radius: 6px;
					cursor: pointer;
				}
				.btn.dim {
					color: #999;
					background: #ccc;
					cursor: pointer;
				}
				
			}
			.to {
				text-align: right;
				.link {
					color: #0031BD;
					font-size: 14px;
				}
			}
			.pic {
				display: flex;
				.in {
					flex: 1;
					margin-right: 10px;
				}
				.img {
					width: 100px;
					height: auto;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
